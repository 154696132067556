@import 'calo/variables';
@import 'calo/typography';
@import 'calo/misc';

body {
  font-family: 'Boxed', sans-serif !important;
}

@font-face {
  font-family: 'Boxed';
  src: url('../fonts/Boxed/BoxedRegular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Boxed';
  src: url('../fonts/Boxed/BoxedMedium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Boxed';
  src: url('../fonts/Boxed/BoxedSemibold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Boxed';
  src: url('../fonts/Boxed/BoxedHeavy.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Protector';
  src: url('../fonts/Protector/Protector.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Bronson';
  src: url('../fonts/Bronson/BRONSON-Black.ttf');
  font-weight: 900;
}